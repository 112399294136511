/*
 * @Author: Walker Qin
 * @Description:
 * @Date: 2022-10-08 14:45:50
 * @LastEditTime: 2022-10-20 15:45:48
 */
import Box from '@mui/material/Box'
import FooterAccordion from 'src/layouts/FooterAccordion'
import FooterSubMenu from './FooterSubMenu'
import menus from './SiteNavMenus/menus.json'

type FooterCollectionMenuProps = {
  handle: string
}

export default function FooterCollectionMenu({
  handle,
}: FooterCollectionMenuProps) {
  const collection = menus.find((item) => item.url === `/collections/${handle}`)

  if (!collection) return <></>

  return (
    <FooterAccordion
      title={collection.title}
      href={`https://${process.env.NEXT_PUBLIC_SHOPIFY_PRIMARY_DOMAIN}${collection.url}`}
    >
      <Box
        sx={{
          py: {
            xs: 1,
            md: 0,
          },
        }}
      >
        {collection.children.map((subMenu) => (
          <Box key={subMenu.title}>
            <FooterSubMenu
              title={`${subMenu.title}`}
              url={`${
                subMenu.url.includes('landing')
                  ? ''
                  : `https://${process.env.NEXT_PUBLIC_SHOPIFY_PRIMARY_DOMAIN}`
              }${subMenu.url}`}
            />
          </Box>
        ))}
      </Box>
    </FooterAccordion>
  )
}
